define('ember-float-label/mixins/registrable', ['exports', 'ember', 'ember-float-label/components/float-label'], function (exports, _ember, _emberFloatLabelComponentsFloatLabel) {
  'use strict';

  var on = _ember['default'].on;
  var scheduleOnce = _ember['default'].run.scheduleOnce;

  exports['default'] = _ember['default'].Mixin.create({
    _registerWithFloatLabel: on('init', function () {
      var _this = this;

      var parent = this.get('parentView');
      if (parent instanceof _emberFloatLabelComponentsFloatLabel['default']) {
        scheduleOnce('afterRender', function () {
          parent.send('register', _this);
        });
      }
    })
  });
});