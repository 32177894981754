define('cecc/mixins/list-controller-mixin', ['exports', 'ember', 'cecc/mixins/model-array'], function (exports, _ember, _ceccMixinsModelArray) {
    'use strict';

    exports['default'] = _ember['default'].Mixin.create({
        queryParams: ['sort', 'page', 'filter', 'query', 'refresh'],
        preFilter: false,
        showFilter: false,
        fields: [],
        filter: null,
        filterQueries: null,
        filterQuery: null,
        includes: [],
        count: null,
        page: 1,
        limit: 100,
        orderBy: 'id',
        orderDirection: 'desc',
        query: '',
        resource: '',
        hasMore: (function () {
            if (this.get('model').length < this.get('count')) {
                return true;
            }
            return false;
        }).property('count', 'model.[]'),
        buildQuery: function buildQuery(params) {
            params = params || false;
            var output = {};

            var filter = this.get('preFilter') || '';

            if (this.get('filter')) {
                filter = this.get('filterQueries')[this.get('filter')];
            }

            if (params && params.filter) {
                filter = this.get('filterQueries')[params.filter];
            }

            if (this.get('filterQuery')) {
                filter = this.get('filterQuery');
            }

            var str = '';

            if (params && params.query) {
                str = params.query;
            }

            if (this.get('query') && this.get('query').length !== 0) {
                str = this.get('query');
            }

            if (str.length === 0) {
                return filter;
            }

            if (filter && filter.length !== 0) {
                output[""] = filter;
            }

            if (this.get('fields') && this.get('fields').length !== 0 && filter && filter.length !== 0) {
                output["AND"] = {};
            }

            _ember['default'].$.each(this.get('fields'), function (k, v) {
                if (filter && filter.length !== 0) {
                    output["AND"][v] = { "like": str, "op": "OR" };
                } else {
                    output[v] = { "like": str, "op": "OR" };
                }
            });

            return output;
        },
        actions: {
            search: function search() {
                var self = this;
                this.set('page', 1);
                this.set('showFilter', false);

                this.api.query(this.get('resource'), this.buildQuery(), this.get('includes'), 1, this.get('limit'), this.get('orderBy'), this.get('orderDirection')).then(function (r) {
                    self.set('count', r.count);
                    self.set('model', _ceccMixinsModelArray['default'].create().make(r.results));
                });
            },
            more: function more() {
                var page = this.get('page') + 1;
                var self = this;
                this.api.query(this.get('resource'), this.buildQuery(), this.get('includes'), page, this.get('limit'), this.get('orderBy'), this.get('orderDirection')).then(function (r) {
                    self.get('model').pushObjects(_ceccMixinsModelArray['default'].create().make(r.results));
                });

                this.set('page', page);
            },
            resetFilter: function resetFilter() {

                var self = this;
                this.set('page', 1);

                this.set('filter', null);
                this.set('filterQuery', null);

                this.api.query(this.get('resource'), this.buildQuery(), this.get('includes'), 1, this.get('limit'), this.get('orderBy'), this.get('orderDirection')).then(function (r) {
                    self.set('count', r.count);
                    self.set('model', _ceccMixinsModelArray['default'].create().make(r.results));
                });
            },
            performFilter: function performFilter(input) {

                var self = this;
                this.set('page', 1);

                this.set('filterQuery', null);
                this.set('filter', input);

                this.api.query(this.get('resource'), this.buildQuery(), this.get('includes'), 1, this.get('limit'), this.get('orderBy'), this.get('orderDirection')).then(function (r) {
                    self.set('count', r.count);
                    self.set('model', _ceccMixinsModelArray['default'].create().make(r.results));
                });
            }
        }
    });
});