define('cecc/components/search-box', ['exports', 'ember', 'cecc/templates/components/search-box'], function (exports, _ember, _ceccTemplatesComponentsSearchBox) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsSearchBox['default'],
		i18n: _ember['default'].inject.service(),
		placeholder: (function () {

			if (typeof this.get('targetObject').get('filter') === "undefined" || !this.get('targetObject').get('filter')) {
				return 'Suchbegriff...';
			} else {
				return this.get('i18n').t('filter.placeholder', { filter: this.get('targetObject').get('filter') });
			}
		}).property('targetObject.filter'),
		hasActiveFilter: (function () {
			var filterQuery = !(typeof this.get('targetObject').get('filterQuery') === "undefined" || this.get('targetObject').get('filterQuery') === null || this.get('targetObject').get('filterQuery') === false);
			var filter = !(typeof this.get('targetObject').get('filter') === "undefined" || this.get('targetObject').get('filter') === null || this.get('targetObject').get('filter') === false);
			if (filterQuery && !filter) {
				return true;
			}
			if (!filterQuery && filter) {
				return true;
			}
			if (filterQuery && filter) {
				return true;
			}
			return false;
		}).property('targetObject.filter', 'targetObject.filterQuery'),
		hasFilter: (function () {
			if (typeof this.get('targetObject').get('filterNames') === "undefined" || !this.get('targetObject').get('filterNames') || this.get('targetObject').get('filterNames') === false || this.get('targetObject').get('filterNames') === []) {
				return false;
			}
			return true;
		}).property('targetObject.filter'),
		actions: {
			performFilter: function performFilter(filter) {
				this.get('targetObject').send('performFilter', filter);
			},
			resetFilter: function resetFilter() {
				this.get('targetObject').send('resetFilter');
			},
			search: function search() {
				this.get('targetObject').send('search');
			},
			toggleExtendedFilter: function toggleExtendedFilter() {
				this.get('targetObject').set('showFilter', !this.get('targetObject').get('showFilter'));
			}
		}
	});
});