define('cecc/mixins/model', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		_model: null,
		_count: 0,
		rollback: function rollback() {
			this.setProperties(this._model);
		},
		make: function make(prop) {
			this._model = prop;
			this.setProperties(prop);
			return this;
		},
		save: function save() {
			this._model = this.getProperties();
		},
		toArray: function toArray() {
			return JSON.parse(JSON.stringify(this));
		}
	});
});