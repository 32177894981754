define('cecc/components/login-modal', ['exports', 'ember', 'cecc/templates/components/login-modal'], function (exports, _ember, _ceccTemplatesComponentsLoginModal) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsLoginModal['default'],
		modal: _ember['default'].inject.service('login-modal'),
		source: _ember['default'].computed.oneWay('modal'),
		visible: false,
		backdrop: true,
		fade: true,
		absolute: false,
		init: function init() {
			this._super();
			this.get('source').setTarget(this);
		},
		showModal: (function () {
			if (this.get('visible')) {
				this.$().find('.modal').modal('show');
			} else {
				this.$().find('.modal').modal('hide');
			}
		}).observes('visible'),
		didInsertElement: function didInsertElement() {
			var self = this;
			this.$().find('.modal').modal({
				backdrop: this.get('backdrop'),
				show: false
			}).on('hide.bs.modal', function () {
				self.set('visible', false);
			});
		}
	});
});