define('cecc/components/file-drop', ['exports', 'ember', 'cecc/templates/components/file-drop'], function (exports, _ember, _ceccTemplatesComponentsFileDrop) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsFileDrop['default'],
		path: '',
		didInsertElement: function didInsertElement() {
			var self = this;

			this.$().find('#dropzone').filedrop({
				fallback_id: 'file_upload', // an identifier of a standard file input element, becomes the target of "click" events on the dropzone
				url: self.data.apiUrl + 'upload', // upload handler, handles each file separately, can also be a function taking the file and returning a url
				paramname: 'file', // POST parameter name used on serverside to reference file, can also be a function taking the filename and returning the paramname
				withCredentials: true, // make a cross-origin request with cookies
				data: {
					path: self.get('path') },
				// send POST variables
				allowedfiletypes: ['image/jpeg', 'image/png', 'image/gif'], // filetypes allowed by Content-Type.  Empty array means no restrictions
				allowedfileextensions: ['.jpg', '.jpeg', '.png', '.gif'], // file extensions allowed. Empty array means no restrictions
				maxfiles: 1,
				maxfilesize: 20, // max file size in MBs
				uploadFinished: function uploadFinished(i, file, response, time) {
					time = time;
					i = i;
					file = file;
					// response is the data you got back from server in JSON format.
					self.set('image', response.results);
				}
			});
		}
	});
});