define('cecc/initializers/i18n', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    ['component', 'controller', 'model', 'route'].forEach(function (type) {
      application.inject(type, 'i18n', 'service:i18n');
    });
  }

  exports['default'] = {
    name: 'i18n',
    initialize: initialize
  };
});