define('ember-float-label/components/float-label', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _Ember$computed = _ember['default'].computed;
  var bool = _Ember$computed.bool;
  var oneWay = _Ember$computed.oneWay;

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'label',
    classNames: ['float-label'],
    classNameBindings: ['hasValue'],
    field: null,
    label: oneWay('field.floatLabel'),
    value: oneWay('field.value'),
    hasValue: bool('value'),

    actions: {
      register: function register(field) {
        this.set('field', field);
      }
    }
  });
});