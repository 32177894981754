define("cecc/templates/components/process-bar", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "modules/cecc/templates/components/process-bar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "progress");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "role", "progressbar");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("%\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'aria-valuenow');
        morphs[2] = dom.createAttrMorph(element0, 'aria-valuemin');
        morphs[3] = dom.createAttrMorph(element0, 'aria-valuemax');
        morphs[4] = dom.createAttrMorph(element0, 'style');
        morphs[5] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["progress-bar ", ["get", "style", ["loc", [null, [2, 28], [2, 33]]]]]]], ["attribute", "aria-valuenow", ["concat", [["get", "now", ["loc", [null, [2, 73], [2, 76]]]]]]], ["attribute", "aria-valuemin", ["concat", [["get", "min", ["loc", [null, [2, 97], [2, 100]]]]]]], ["attribute", "aria-valuemax", ["concat", [["get", "max", ["loc", [null, [2, 121], [2, 124]]]]]]], ["attribute", "style", ["concat", ["width: ", ["get", "now", ["loc", [null, [2, 144], [2, 147]]]], "%;"]]], ["content", "now", ["loc", [null, [3, 2], [3, 9]]]]],
      locals: [],
      templates: []
    };
  })());
});