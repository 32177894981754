define('cecc/services/io', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend({
		instance: null,
		connect: function connect(controller, user) {
			var io = window.io(this.config.host, { query: { user_id: user.id }, 'forceNew': true });
			this.set('config.user', user);

			io.on('news', function (d) {
				controller.notify.info(d);
			});

			this.set('instance', io);
		},
		disconnect: function disconnect() {
			this.instance.disconnect();
		}
	});
});