define('cecc/initializers/data', ['exports'], function (exports) {
	'use strict';

	exports.initialize = initialize;

	function initialize(application) {
		application.inject('route', 'data', 'service:data');
		application.inject('controller', 'data', 'service:data');
		application.inject('component', 'data', 'service:data');
		application.inject('adapter', 'data', 'service:data');
	}

	exports['default'] = {
		name: 'data',
		before: 'api',
		initialize: initialize
	};
});