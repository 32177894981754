define('cecc/components/toggle-nav', ['exports', 'ember', 'cecc/templates/components/toggle-nav'], function (exports, _ember, _ceccTemplatesComponentsToggleNav) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsToggleNav['default'],
		actions: {
			toggle: function toggle() {
				var toggle;
				if (typeof this.get('data.toggle') === 'undefined') {
					toggle = true;
				} else {
					toggle = !this.get('data.toggle');
				}
				this.set('data.toggle', toggle);
			}
		}
	});
});