define('cecc/helpers/to-html', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Helper.helper(function (params) {

		var html = params[0] || "";

		if (html === '0') {
			return '';
		}

		if (/<HEAD>/.test(html) || /<HTML>/.test(html) || /<STYLE>/.test(html)) {

			var removeElements = function removeElements(text, selector) {
				var wrapped = $("<div>" + text + "</div>");
				wrapped.find(selector).remove();
				return wrapped.html();
			};

			html = removeElements(html, "style");
			return html;
		}

		return html.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
	});
});