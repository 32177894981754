define('cecc/mixins/detail-route-mixin', ['exports', 'ember', 'cecc/mixins/model'], function (exports, _ember, _ceccMixinsModel) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		queryParams: {
			r: {
				refreshModel: true
			}
		},
		model: function model(params) {
			var c = this.controllerFor(this.get('control'));
			return this.api.findQuery(c.get('resource'), params.id, c.get('includes')).then(function (r) {
				return _ceccMixinsModel['default'].create().make(r.results);
			});
		},
		resetController: function resetController(controller /*, isExiting, transition*/) {
			controller.set('data.detail', false);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('data.detail', true);
		},
		afterModel: function afterModel(model, transition) {
			var c = this.controllerFor(this.get('control'));
			this.cache.setCache(c.get('resource'), model);
			this.set('data.detail', true);
			this._super(model, transition);
		},
		beforeModel: function beforeModel(transition) {
			var user = this.get('data.user');
			this.set('data.transition', null);

			if (!user) {
				this.set('data.transition', transition);
				this.transitionTo('login');
			}
		},
		actions: {
			willTransition: function willTransition(transition) {
				var user = this.get('data.user');
				this.set('data.transition', null);

				if (!user) {
					this.set('data.transition', transition);
					this.transitionTo('login');
				}
			},
			error: function error(_error, transition) {
				if (_error) {
					this.set('data.error', _error);
				}
				if (_error && _error.status === 401) {
					this.set('data.transition', transition);
				}
				return true;
			}
		}
	});
});