define('cecc/initializers/cache', ['exports'], function (exports) {
	'use strict';

	exports.initialize = initialize;

	function initialize(app) {

		app.inject('route', 'cache', 'service:cache');
		app.inject('controller', 'cache', 'service:cache');
	}

	exports['default'] = {
		name: 'cache',
		before: 'api',
		initialize: initialize
	};
});