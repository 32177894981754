define('cecc/helpers/convert-number', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Helper.helper(function (number) {
		var reg = /[,]/;
		if (reg.test(number) === true) {
			return number;
		} else {
			return parseFloat(number).formatNumber(2, '.', ',');
		}
	});
});