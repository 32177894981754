define("cecc/templates/components/modal-popup", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 6
          }
        },
        "moduleName": "modules/cecc/templates/components/modal-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "tabindex", "-1");
        dom.setAttribute(el1, "role", "dialog");
        dom.setAttribute(el1, "aria-labelledby", "myModalLabel");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "role", "document");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["modal ", ["subexpr", "if", [["get", "fade", ["loc", [null, [1, 23], [1, 27]]]], "animated fadeIn", ""], [], ["loc", [null, [1, 18], [1, 50]]]], " ", ["subexpr", "if", [["get", "absolute", ["loc", [null, [1, 56], [1, 64]]]], "absolute", ""], [], ["loc", [null, [1, 51], [1, 80]]]], " ", ["subexpr", "if", [["get", "backdrop", ["loc", [null, [1, 86], [1, 94]]]], "backdrop", ""], [], ["loc", [null, [1, 81], [1, 110]]]]]]], ["attribute", "class", ["concat", ["modal-dialog ", ["get", "size", ["loc", [null, [2, 28], [2, 32]]]]]]], ["content", "yield", ["loc", [null, [4, 3], [4, 12]]]]],
      locals: [],
      templates: []
    };
  })());
});