define('cecc/components/modal-popup', ['exports', 'ember', 'cecc/templates/components/modal-popup'], function (exports, _ember, _ceccTemplatesComponentsModalPopup) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsModalPopup['default'],
		tagName: 'div',
		classNames: [],
		fade: true,
		open: false,
		absolute: false,
		backdrop: false,
		didInsertElement: function didInsertElement() {

			var self = this;

			this.$().find('.modal').modal({
				backdrop: this.get('backdrop'),
				show: false
			}).on('hide.bs.modal', function () {
				self.set('open', false);
			});

			if (this.get('open')) {

				this.$().find('.modal').modal('show');
			}
		},
		openClose: (function () {

			if (this.get('open')) {
				this.$().find('.modal').modal('show');
			} else {
				this.$().find('.modal').modal('hide');
			}
		}).observes('open')
	});
});