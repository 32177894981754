define('cecc/services/login-modal', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend({
		show: function show() {
			this.get('target').set('visible', true);
		},
		hide: function hide() {
			this.get('target').set('visible', false);
		},
		setTarget: function setTarget(target) {
			this.set('target', target);
		}
	});
});