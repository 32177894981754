define('cecc/components/process-bar', ['exports', 'ember', 'cecc/templates/components/process-bar'], function (exports, _ember, _ceccTemplatesComponentsProcessBar) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		layout: _ceccTemplatesComponentsProcessBar['default'],
		now: 0,
		min: 0,
		max: 100,
		style: ''
	});
});