define('cecc/initializers/api', ['exports'], function (exports) {
	'use strict';

	exports.initialize = initialize;

	function initialize(app) {

		app.inject('route', 'api', 'service:api');
		app.inject('controller', 'api', 'service:api');
		app.inject('component', 'api', 'service:api');
	}

	exports['default'] = {
		name: 'api',
		after: 'data',
		initialize: initialize
	};
});