define("ember-cli-toggle/components/x-toggle/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "modules/ember-cli-toggle/components/x-toggle/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["toggle-text toggle-prefix ", ["get", "size", ["loc", [null, [2, 42], [2, 46]]]]]]], ["content", "offLabelValue", ["loc", [null, [3, 2], [3, 19]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "modules/ember-cli-toggle/components/x-toggle/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["toggle-text toggle-postfix ", ["get", "size", ["loc", [null, [16, 43], [16, 47]]]]]]], ["content", "onLabelValue", ["loc", [null, [17, 2], [17, 18]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "modules/ember-cli-toggle/components/x-toggle/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createAttrMorph(element2, 'for');
        morphs[4] = dom.createAttrMorph(element2, 'data-tg-off');
        morphs[5] = dom.createAttrMorph(element2, 'data-tg-on');
        morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showLabels", ["loc", [null, [1, 6], [1, 16]]]]], [], 0, null, ["loc", [null, [1, 0], [5, 7]]]], ["inline", "input", [], ["id", ["subexpr", "@mut", [["get", "forId", ["loc", [null, [7, 11], [7, 16]]]]], [], []], "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "toggled", ["loc", [null, [7, 41], [7, 48]]]]], [], []], "class", "x-toggle", "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [7, 75], [7, 83]]]]], [], []], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [7, 89], [7, 93]]]]], [], []]], ["loc", [null, [7, 0], [7, 95]]]], ["attribute", "class", ["concat", ["x-toggle-btn ", ["get", "themeClass", ["loc", [null, [9, 29], [9, 39]]]], " ", ["get", "size", ["loc", [null, [9, 44], [9, 48]]]], " ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [9, 56], [9, 64]]]], "x-toggle-disabled"], [], ["loc", [null, [9, 51], [9, 86]]]]]]], ["attribute", "for", ["get", "forId", ["loc", [null, [10, 8], [10, 13]]]]], ["attribute", "data-tg-off", ["get", "offLabelValue", ["loc", [null, [11, 16], [11, 29]]]]], ["attribute", "data-tg-on", ["get", "onLabelValue", ["loc", [null, [12, 15], [12, 27]]]]], ["block", "if", [["get", "showLabels", ["loc", [null, [15, 6], [15, 16]]]]], [], 1, null, ["loc", [null, [15, 0], [19, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});