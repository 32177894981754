define('cecc/mixins/no-authentication', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		queryParams: {
			refresh: {
				refreshModel: true
			}
		},
		beforeModel: function beforeModel() {
			var user = this.get('data.user');

			if (user) {
				this.transitionTo('home');
			}
		}

	});
});