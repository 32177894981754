define('cecc/components/else-cond', ['exports', 'ember', 'cecc/templates/components/else-cond'], function (exports, _ember, _ceccTemplatesComponentsElseCond) {
    'use strict';

    exports['default'] = _ember['default'].Component.extend({
        layout: _ceccTemplatesComponentsElseCond['default'],
        tagName: '',
        isCond: (function () {
            var v1 = this.get('param1');
            var v2 = this.get('param2');
            var operator = this.get('op') || false;
            switch (operator) {
                case "==":
                    return v1 == v2;

                case "!=":
                    return v1 != v2;

                case "===":
                    return v1 === v2;

                case "!==":
                    return v1 !== v2;

                case "&&":
                    return v1 && v2;

                case "||":
                    return v1 || v2;

                case "<":
                    return v1 < v2;

                case "<=":
                    return v1 <= v2;

                case ">":
                    return v1 > v2;

                case ">=":
                    return v1 >= v2;

                default:
                    return false;
            }
        }).property('param1', 'param2')
    });
});