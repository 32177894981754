define('cecc/services/cache', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend({
		cache: {},
		compare: function compare(field, newModel) {

			var oldModel = this.getCache(field);

			newModel = JSON.parse(JSON.stringify(newModel));
			var model = {};

			_ember['default'].$.each(newModel, function (k, v) {
				if (newModel[k] !== oldModel[k]) {
					model[k] = v;
				}
			});

			return model;
		},
		setCache: function setCache(data, value) {
			this.cache[data] = JSON.stringify(value);
		},
		getCache: function getCache(key) {
			var json;

			try {
				json = JSON.parse(this.cache[key]);
			} catch (e) {
				json = {};
			}

			return json;
		}
	});
});