define('cecc/mixins/list-route-mixin', ['exports', 'ember', 'cecc/mixins/model-array'], function (exports, _ember, _ceccMixinsModelArray) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		queryParams: {
			refresh: {
				refreshModel: true
			}
		},
		model: function model(params) {
			var c = this.controllerFor(this.get('control'));
			return this.api.query(c.get('resource'), c.buildQuery(params), c.get('includes'), 1, c.get('limit') * params.page, c.get('orderBy'), c.get('orderDirection')).then(function (r) {
				c.set('count', r.count);
				return _ceccMixinsModelArray['default'].create().make(r.results);
			});
		},
		resetController: function resetController(controller, isExiting /*, transition*/) {
			if (isExiting) {
				controller.set('page', 1);
			}
		},
		beforeModel: function beforeModel(transition) {
			var user = this.get('data.user');
			this.set('data.transition', null);

			if (!user) {
				this.set('data.transition', transition);
				this.transitionTo('login');
			}
		},
		actions: {
			willTransition: function willTransition(transition) {
				var user = this.get('data.user');
				this.set('data.transition', null);

				if (!user) {
					this.set('data.transition', transition);
					this.transitionTo('login');
				}

				return true;
			},
			error: function error(_error, transition) {
				if (_error) {
					this.set('data.error', _error);
				}
				if (_error && _error.status === 401) {
					this.set('data.transition', transition);
				}
				return true;
			}
		}
	});
});