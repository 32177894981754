define('cecc/mixins/model-array', ['exports', 'ember', 'cecc/mixins/model'], function (exports, _ember, _ceccMixinsModel) {
	'use strict';

	exports['default'] = _ember['default'].Object.extend({
		make: function make(arr) {
			var response = [];
			_ember['default'].$.each(arr, function (k, v) {
				response.push(_ceccMixinsModel['default'].create().make(v));
			});
			return response;
		}
	});
});