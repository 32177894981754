define('cecc/components/datetime-picker', ['exports', 'ember', 'cecc/templates/components/datetime-picker'], function (exports, _ember, _ceccTemplatesComponentsDatetimePicker) {
    'use strict';

    exports['default'] = _ember['default'].Component.extend({
        layout: _ceccTemplatesComponentsDatetimePicker['default'],
        picker: null,
        classNames: ['input-small'],
        time: true,
        def: true,
        format: moment()._locale._longDateFormat.L + ' ' + moment()._locale._longDateFormat.LT,
        date: moment()._locale._longDateFormat.L,
        dbFormat: 'YYYY-MM-DD HH:mm:ss',
        placeholder: _ember['default'].computed.alias('format'),
        /**
         * Create function */
        didInsertElement: function didInsertElement() {
            var self = this;
            var def, format;

            if (moment(this.get('datetime')).isValid()) {
                def = this.get('datetime');
            } else {
                if (this.get('def')) {
                    def = moment();
                } else {
                    def = null;
                }
            }

            if (this.get('time')) {
                format = this.get('format');
            } else {
                format = this.get('date');
            }

            var picker = this.$().find('.date').datetimepicker({
                format: format,
                locale: 'de',
                focusOnShow: false,
                defaultDate: def,
                icons: {
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                    up: "fa fa-arrow-up",
                    down: "fa fa-arrow-down",
                    previous: "fa fa-chevron-left",
                    next: "fa fa-chevron-right"
                }
            });

            this.set('picker', picker);

            picker.on('dp.change', function (ev) {
                self.set('datetime', ev.date.format(self.get('dbFormat')));
            });
        },

        datetimeChanged: (function () {
            // get model datetime
            var modelDatetime = this.get('datetime');
            // get picker datetime
            var picker = this.get('picker');
            var pickerDatetime = picker.datetimepicker('date');
            // if not the same the overwrite model over picker
            if (modelDatetime !== pickerDatetime) {
                picker.datetimepicker('setValue', modelDatetime);
            }
        }).observes('datetime')

    });
});