define("cecc/templates/components/file-drop", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 6
          }
        },
        "moduleName": "modules/cecc/templates/components/file-drop.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "text-center");
        var el2 = dom.createElement("small");
        var el3 = dom.createTextNode("Datei hier hereinziehen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "dropzone");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "type", "file");
        dom.setAttribute(el2, "name", "file");
        dom.setAttribute(el2, "id", "file_upload");
        dom.setAttribute(el2, "style", "display:none;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(1);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        return morphs;
      },
      statements: [["attribute", "style", ["concat", ["width: 140px; height: 140px; border: 1px solid #ddd; margin: 0 auto; cursor:pointer;background-image: url('http://assets.comulus.net/uploads/", ["subexpr", "if", [["get", "image", ["loc", [null, [2, 172], [2, 177]]]], ["get", "image", ["loc", [null, [2, 178], [2, 183]]]], "noimage.png"], [], ["loc", [null, [2, 167], [2, 199]]]], "'); background-size: contain; background-position: center center; background-repeat: no-repeat;"]]]],
      locals: [],
      templates: []
    };
  })());
});