define('cecc/helpers/status-name', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Helper.helper(function (params) {
		var status = params[0];
		var res;
		switch (status) {
			case "opportunity":
				res = 'Opportunity';
				break;
			case "declined":
				res = 'Abgelehnt';
				break;
			case "customer":
				res = 'Kunde';
				break;
			case "deleted":
				res = 'Gelöscht';
				break;
			case "accepted":
				res = 'Angenommen';
				break;
			case "lost":
				res = 'Verloren';
				break;
			case "order":
				res = 'Auftrag';
				break;
			case "assigned":
				res = 'Zugewiesen';
				break;
			case "unassigned":
				res = 'nicht zugewiesen';
				break;
			case "created":
				res = 'Erstellt';
				break;
			case "draft":
				res = 'Entwurf';
				break;
			case "open":
				res = 'Offen';
				break;
			case "active":
				res = 'Aktiv';
				break;
			case "disabled":
				res = 'Inaktiv';
				break;
			case "closed":
				res = 'Geschlossen';
				break;
			case "paid":
				res = 'Bezahlt';
				break;
			case "planed":
				res = 'Geplant';
				break;
			case "work":
				res = 'In Arbeit';
				break;
			case "finished":
				res = 'Fertig';
				break;
			case "0":
				res = 'Deaktiviert';
				break;
			case "1":
				res = 'Aktiviert';
				break;
			case "invoice":
				res = 'Invoice';
				break;
			case "draft":
				res = 'Draft';
				break;
			case "bid":
				res = 'Voranschlag';
				break;
			case "":
				res = 'Erstellt';
				break;
			default:
				res = status;
				break;

		}

		return new _ember['default'].String.htmlSafe(res);
	});
});