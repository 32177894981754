define('cecc/mixins/subdetail-controller-mixin', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		queryParams: ['edit', 'ref'],
		edit: false,
		includes: [],
		resource: '',
		rollback: false,
		saveItem: function saveItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.get('model');
			var compared = this.cache.compare(this.get('resource'), model);

			this.api.save(this.get('resource'), model.id, { data: JSON.stringify(compared) }).then(function (r) {
				self.notify.info(r.message);
				self.set('edit', false);
				if (typeof callback === "function") {
					callback(r);
				}
				self.cache.setCache(self.get('resource'), model);
			})['catch'](function (r) {
				self.notify.error(r.message);
			});
		},
		removeItem: function removeItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.get('model');

			this.api.remove(this.get('resource'), model.id).then(function (r) {
				self.set('remove', false);
				self.notify.info(r.message);
				if (typeof callback === "function") {
					callback(r);
				}
			})['catch'](function (r) {
				self.notify.error(r.message);
			});
		},
		createItem: function createItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.data.parse(this.get('model'));

			this.api.create(this.get('resource'), { data: JSON.stringify(model) }).then(function (r) {
				self.notify.info(r.message);
				self.set('model', {});
				if (typeof callback === "function") {
					callback(r);
				}
			})['catch'](function (r) {
				self.notify.error(r.message);
			});
		},
		replicateItem: function replicateItem(callback) {
			callback = callback || null;
			var self = this;
			var model = this.data.parse(this.get('model'));

			this.api.post(this.get('resource') + '/' + model.id + '/replicate').then(function (r) {
				self.notify.info(r.message);
				if (typeof callback === "function") {
					callback(r);
				}
			})['catch'](function (r) {
				self.notify.error(r.message);
			});
		},
		refresh: function refresh() {
			this.set('ref', Date.now());
		},
		actions: {
			save: function save() {
				this.saveItem();
			},
			remove: function remove() {
				this.removeItem();
			},
			create: function create() {
				this.createItem();
			},
			toggleRemove: function toggleRemove() {
				this.set('remove', !this.get('remove'));
			},
			toggleEdit: function toggleEdit() {
				if (this.get('edit') === true) {
					if (typeof this.get('model').rollback !== 'undefined' && this.get('rollback')) {
						this.get('model').rollback();
					}
				}
				this.set('edit', !this.get('edit'));
			},
			replicate: function replicate() {
				this.replicateItem();
			}
		}
	});
});