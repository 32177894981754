define('cecc/initializers/login-modal', ['exports'], function (exports) {
	'use strict';

	exports.initialize = initialize;

	function initialize() {
		var application = arguments[1] || arguments[0];
		application.inject('route', 'login-modal', 'service:login-modal');
		application.inject('controller', 'login-modal', 'service:login-modal');
	}

	exports['default'] = {
		name: 'login-modal',
		initialize: initialize
	};
});