define('cecc/mixins/authentication', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Mixin.create({
		queryParams: {
			refresh: {
				refreshModel: true
			}
		},
		beforeModel: function beforeModel(transition) {
			var user = this.get('data.user');
			this.set('data.transition', null);

			if (!user) {
				this.set('data.transition', transition);
				this.transitionTo('login');
			}
		},
		actions: {
			willTransition: function willTransition(transition) {
				var user = this.get('data.user');
				this.set('data.transition', null);

				if (!user) {
					this.set('data.transition', transition);
					this.transitionTo('login');
				}
			},
			error: function error(_error, transition) {
				if (_error) {
					this.set('data.error', _error);
				}
				if (_error && _error.status === 401) {
					this.set('data.transition', transition);
				}
				return true;
			}
		}
	});
});