define('cecc/helpers/just-date', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Helper.helper(function (params) {
		var date = params[0];
		if (moment(date).isValid()) {
			return moment(date).format('L LT');
		}

		return '-';
	});
});