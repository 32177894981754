define('cecc/services/api', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Service.extend({
		modal: _ember['default'].inject.service('login-modal'),
		data: _ember['default'].inject.service('data'),
		headers: null,
		contentType: null,
		accepts: null,
		find: function find(table, id, query) {
			query = query || {};
			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}
			return this.ajax('GET', table + id, query);
		},
		findQuery: function findQuery(table, id, includes, page, limit, orderBy, direction, additional) {
			return this.getQuery(id, table, {}, includes, page, limit, orderBy, direction, additional);
		},
		findAll: function findAll(table, query) {
			query = query || {};
			return this.ajax('GET', table, query);
		},
		save: function save(table, id, data) {
			data = data || {};
			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}
			return this.ajax('PUT', table + id, data);
		},
		create: function create(table, data) {
			data = data || {};
			return this.ajax('POST', table, data);
		},
		post: function post(table, data) {
			data = data || {};
			return this.ajax('POST', table, data);
		},
		remove: function remove(table, id) {
			if (id === undefined || !id) {
				id = '';
			} else {
				id = '/' + id;
			}
			return this.ajax('DELETE', table + id, {});
		},
		'delete': function _delete(table, id) {
			return this.remove(table, id);
		},
		query: function query(table, _query, includes, page, limit, orderBy, direction, additional) {
			return this.getQuery('query', table, _query, includes, page, limit, orderBy, direction, additional);
		},
		count: function count(table, query, includes, page, limit, orderBy, direction, additional) {
			return this.getQuery('count', table, query, includes, page, limit, orderBy, direction, additional);
		},
		getQuery: function getQuery(urlBag, table, query, includes, page, limit, orderBy, direction, additional) {
			urlBag = urlBag || 'query';
			query = query || {};
			includes = includes || [];
			limit = limit || 100;
			page = page || 1;
			orderBy = orderBy || 'id';
			direction = direction || 'desc';
			additional = additional || '';

			var t = this;
			var url = table + '/' + urlBag;
			url = url + '?query=' + t.toStr(query);
			url = url + '&include=' + t.toStr(includes);
			url = url + '&page=' + page;
			url = url + '&limit=' + limit;
			url = url + '&orderBy=' + orderBy;
			url = url + '&orderDirection=' + direction;
			url = url + additional;

			return this.ajax('GET', url, {});
		},
		ajax: function ajax(type, url, query) {
			var self = this;
			var options = {
				type: type,
				data: query,
				async: !this.config.sync
			};

			if (this.config.withCredentials) {
				options.xhrFields = {
					withCredentials: true
				};
			}

			if (this.accepts !== null) {
				options.accepts = this.accepts;
			}

			if (this.contentType !== null) {
				options.contentType = this.contentType;
			}

			if (this.headers !== null) {
				options.headers = this.headers;
			}

			url = this.config.apiUrl + url;

			self.info('URL: ' + url, query);

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_ember['default'].$.ajax(url, options).then(function (data) {
					self.log(data);
					_ember['default'].run(null, resolve, data);
				}, function (jqXHR) {

					var error = typeof jqXHR.responseJSON === 'object' ? jqXHR.responseJSON : {};
					error.raw = jqXHR.responseText;
					error.status = jqXHR.status;
					error.message = error.message || '';

					console.error(error);

					if (jqXHR.status === 401 && self.get('data').get('user')) {
						self.get('modal').show();
						// show Login modal
					}
					jqXHR.then = null; // tame jQuery's ill mannered promises

					_ember['default'].run(null, reject, error);
				});
			});
		},
		toStr: function toStr(str) {
			return JSON.stringify(str);
		},
		log: function log() {
			if (this.config.environment === 'development') {
				for (var i = 0; i < arguments.length; i++) {
					console.log(arguments[i]);
				}
			}
		},
		info: function info() {
			if (this.config.environment === 'development') {
				for (var i = 0; i < arguments.length; i++) {
					console.info(arguments[i]);
				}
			}
		}
	});
});